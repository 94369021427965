import React, {useState, useEffect} from 'react';
import AlbumCardPhotoCount from '../AlbumCardPhotoCount/AlbumCardPhotoCount';
import AlbumCardPhotographer from '../AlbumCardPhotographer/AlbumCardPhotographer';
import styled from 'styled-components';

type Props = {
  images: 
    { src: string; 
      href?: string, 
      photographer?: string,
      photoCount?: number 
    }[];
};

const Carousel: React.FC<Props> = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [translateValue, setTranslateValue] = useState(0);
  const IMAGE_WIDTH = 250;

  useEffect(() => {
    const interval = setTimeout(() => {
      if (activeIndex === images.length - 1) {
        setActiveIndex(0);
        setTranslateValue(0);
      } else {
        setActiveIndex(activeIndex + 1);
        setTranslateValue(translateValue - IMAGE_WIDTH);
      }
    }, 4000);
  
    return () => {
      clearTimeout(interval);
    };
  }, [activeIndex, images.length, translateValue, IMAGE_WIDTH]);

  return (
    <Wrapper className="carousel">
      <div className="carousel-wrapper">
        <div
          className="image-container"
          style={{ transform: `translateX(${translateValue}px)` }}
        >
          {images.map((image, index) => (
            <a href={image.href} key={index}>
              <div
                className={`image shadow-lg ${index === activeIndex ? "active" : ""}`}
                style={{ backgroundImage: `url(${image.src})` }}>
                <div className="photo-count-wrapper">
                  <AlbumCardPhotoCount mediaCount={image.photoCount}/>
                </div>
                <div className="photographer-wrapper">
                  <AlbumCardPhotographer photographer={image.photographer}/>
                </div>
              </div>
            </a>
          ))}
          {images.map((image, index) => (
            <a href={image.href} key={index}>
            <div
              className={`image shadow-lg ${index === activeIndex ? "active" : ""}`}
              style={{ backgroundImage: `url(${image.src})` }}>
              <div className="photo-count-wrapper">
                <AlbumCardPhotoCount mediaCount={image.photoCount}/>
              </div>
              <div className="photographer-wrapper">
                <AlbumCardPhotographer photographer={image.photographer}/>
              </div>
            </div>
          </a>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* max-width: 960px; */

  @media only screen and (max-width: 767px) {
    max-width: 100%; 
  }

  .carousel {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.carousel-wrapper {
  display: flex;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.image-container {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.5s ease-in-out;
}

.image:hover {
  transform: translateY(-5px);
}

.image {
  width: 250px;
  height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  margin: .5rem;
  border-radius: 7px;
  transition: transform 0.3s ease;
}

.image.active {
  opacity: 1;
}

.feather {
  display: inline-block;
  vertical-align: middle;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.photo-count-wrapper {
  position: relative;
  div {
    margin-top: 20px;
  }
}
.photographer-wrapper {
  position: relative;

  div {
    margin-top: 190px;
    padding-right: 20px;
    padding-left: 10px;
  }
}

`

export default Carousel