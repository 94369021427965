import { getFirestore, collection, doc, addDoc } from "firebase/firestore";
import { toast } from "react-toastify";

export async function AddToCart(user: any, albumInfo: any, imageUrl: string) {
  if (!user) {
    toast.error("Please log in to purchase...", { theme: "dark" });
    return;
  }

  const db = getFirestore();

  // Reference the user's cart document
  const userCartRef = doc(db, "carts", user.uid);

  // Reference the 'items' subcollection inside the user's cart
  const itemsCollectionRef = collection(userCartRef, "items");

  const itemData = {
    buyer_uid: user.uid,
    date_added_to_cart: new Date().toISOString(),
    album_name: albumInfo.album_name,
    album_slug: albumInfo.album_slug,
    album_date: albumInfo.session_date,
    image_url: imageUrl,
    image_filename: "",
    image_price: albumInfo.album_media_price,
    image_owner_uid: albumInfo.album_owner_id,
    image_owner_username: albumInfo.album_owner_username,
  };

  try {
    // Add the itemData to the 'items' subcollection
    await addDoc(itemsCollectionRef, itemData);

    toast.success("Added to cart", { theme: "dark" });
  } catch (error) {
    console.error("Error adding item to cart:", error);
    toast.error("Failed to add to cart. Please try again.", { theme: "dark" });
  }
}
