import React, { FC } from 'react';
import styled from 'styled-components';
import IconPlus from '../Icons/IconPlus';


interface CreateAlbumBtnProps {
  variant?: 'dark' | 'lite';
  children: any
}

const CreateAlbumBtn: FC<CreateAlbumBtnProps> = ({ variant = 'dark', children}) => {
  return (
    <Wrapper variant={variant}>
      <IconPlus />
      <p>{children}</p>
    </Wrapper>
  );
}

const Wrapper = styled.button<CreateAlbumBtnProps>`
  font-family: 'Montserrat';
  display: flex;
  align-items: center;
  width: 100%;
  height: 37px;
  padding: 1rem;
  color: white;
  border-radius: 7px;
  transition: all 0.3s ease-in-out;

  p {
    font-size: 12px;
    padding-left: 14px;
  }

  @media screen and (max-width: 525px) { 
    p {
      padding-left: 7px;
    }
  }

  ${(props) => {
    if (props.variant === 'dark') {
      return `
        background-color: #3c444c;

        :hover {
          background-color: #3c444c94;
        }
      `;
    } else if (props.variant === 'lite') {
      return `
        background-color: #30363D;
        svg {
          fill: #CBCFD4;
        }
        
        :hover {
          background-color: #30363d9c;
        }
      `;
    }
  }}  
`;

export default CreateAlbumBtn;
