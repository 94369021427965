import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper as SwiperReact, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'; // No need for Lazy module
import ButtonGroup from '../../Components/ButtonGroup/ButtonGroup';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './SwiperThumbnail.css';

// Define the props interface for the SwiperThumbnail component
interface SwiperThumbnailProps {
  images: string[];
  thumbnails: string[];  // Array of thumbnail image URLs
  albumInfo: any;
}

function SwiperThumbnail({ images, thumbnails, albumInfo }: SwiperThumbnailProps) {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [isVideo, setIsVideo] = useState(Boolean);

  // Utility function to determine if the URL is a video
  const isVideoUrl = (url: string): boolean => {
    const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'webm'];
    const extension = url.split('?')[0].split('.').pop()?.toLowerCase(); // Extract the file extension
    return videoExtensions.includes(extension || ''); // Return true/false based on video extension
  };

  // Set the state based on the first URL
  useEffect(() => {
    if (images.length > 0) {
      setIsVideo(isVideoUrl(images[0])); // Only check the first URL
    }
  }, [images]);

  // console.log(albumInfo);

  return (
    !isVideo ? (
      // Show image swiper
      <SwiperWrapper>
        {/* Main Swiper for large images */}
        <SwiperReact
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="imageswiper"
        >
          {images.map((src, index) => (
            <SwiperSlide key={index}>
              <div className="absolute bottom-0 right-0 pb-4 pr-4">
                <ButtonGroup imageUrl={src} albumInfo={albumInfo} />
              </div>
              <img src={src} loading="lazy" />
              <div className="swiper-lazy-preloader"></div>
            </SwiperSlide>
          ))}
        </SwiperReact>
  
        {/* Thumbnail Swiper for small images */}
        <SwiperReact
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={5}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="imageswiper-thumbs"
        >
          {thumbnails.map((src, index) => (
            <SwiperSlide key={index}>
              <img src={src} className="thumbnails" loading="lazy" />
              <div className="swiper-lazy-preloader"></div>
            </SwiperSlide>
          ))}
        </SwiperReact>
      </SwiperWrapper>
    ) : (
      // Show video swiper
      <SwiperWrapper>
        {/* Main Swiper for large images */}
        <SwiperReact
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="videoswiper"
        >
          {images.map((src, index) => (
            <SwiperSlide key={index}>
              <video
                src={src}
                muted
                controls
                autoPlay={false}
                style={{backgroundColor: "#3c444d"}}
              />
            </SwiperSlide>
          ))}
        </SwiperReact>
  
        {/* Thumbnail Swiper for small images */}
        <SwiperReact
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={5}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="videoswiper-thumbs"
        >
          {images.map((src, index) => (
            <SwiperSlide key={index}>
              <video
                src={src}
                muted
                controls={false}
                autoPlay={false}
              />
            </SwiperSlide>
          ))}
        </SwiperReact>
      </SwiperWrapper>
    )
  );  
}

const SwiperWrapper = styled.div`
  /* .thumbnails {
    width: 125px;
    height: 75px;
  } */
`;

export default SwiperThumbnail;
