import React from 'react';
import styled from 'styled-components';
import SpotChip from '../SpotChip/SpotChip';
import { useNavigate } from 'react-router-dom';

const PopularSpotsData = [
  {
    spot_name: 'Pacific Beach',
    spot_displayname: 'Pacific Beach',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'California - South',
    spot_county: ''
  },
  {
    spot_name: 'Scripps Pier - La Jolla',
    spot_displayname: 'Scripps Pier - La Jolla, CA',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'California - South',
    spot_county: ''
  },
  {
    spot_name: 'Ruggles',
    spot_displayname: 'Ruggles, RI',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'Rhode Island',
    spot_county: ''
  },
  {
    spot_name: 'Misquamicut',
    spot_displayname: 'Misquamicut, RI',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'Rhode Island',
    spot_county: ''
  },
  {
    spot_name: 'Oceanside',
    spot_displayname: 'Oceanside, CA',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'California - South',
    spot_county: ''
  },
  {
    spot_name: 'Lighthouse (Point Judith)',
    spot_displayname: 'Point Judith, RI',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'Rhode Island',
    spot_county: ''
  },
  {
    spot_name: "Bailey's Beach",
    spot_displayname: "Bailey's Beach, RI",
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'Rhode Island',
    spot_county: ''
  },
  {
    spot_name: '2nd Beach (Sachuest Beach)',
    spot_displayname: '2nd Beach (Sachuest Beach), RI',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'Rhode Island',
    spot_county: ''
  },
  {
    spot_name: 'Montera State Beach',
    spot_displayname: 'Montera Beach, CA',
    spot_country: 'United States',
    spot_continent: 'North America',
    spot_state: 'California - North',
    spot_county: ''
  }
];

function PopularSpots(props: any) {

  // const { updateCurrentUserSpot, updateCurrentUserState, currentUserState, currentUserSpot } = useUserAuth();
  let navigate = useNavigate();

  const handleSpotClick = (
    spot_name: string,
    spot_country: string,
    spot_continent: string,
    spot_state: string,
    spot_county: string
  ) => {
    navigate(`/${spot_continent}/${spot_country}/${spot_state}/${spot_county ? `/${spot_county}` : ''}${spot_name}`);
  };

  return (
    <div className={props.className}>
      <div className="flex flex-wrap">
        {PopularSpotsData.map((item, index) => (
          <SpotChipWrapper className="grid" key={index}>
            <SpotChip
              key={index} 
              spotName={item.spot_displayname}
              onClick={() => handleSpotClick(item.spot_name, item.spot_country, item.spot_continent, item.spot_state, item.spot_county)}
              statusColor="#ff0000"
              className="bg-color shadow-mg mb-2 less-rounded" />
          </SpotChipWrapper>
        ))}
      </div>
    </div>
  );
}

const SpotChipWrapper = styled.div`
  margin-right: 0.5rem;

  .less-rounded {
    border-radius: 7px;
  }

  .bg-color {
    background-color: #1C2127;
  }

  .bg-color:hover {
    background-color: #1c2127c0;
  }
`;

export default PopularSpots;
