import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { ref as sRef, getDownloadURL, getStorage } from 'firebase/storage';
import { useUserAuth } from '../../Context/UserAuthContext';
import BookingRequestCard from '../../Components/BookingRequestCard/BookingRequestCard';
import { PendingBookingReqs } from '../../Services/firebase/PendingBookingReqs';
import { FlmrBookingReqs } from '../../Services/firebase/FlmrBookingReqs';
import { InProgressBookings } from '../../Services/firebase/InProgressBookings';
import { BookingHistory } from '../../Services/firebase/BookingHistory';
import { Link } from 'react-router-dom';
import UsersAlbums from '../UsersAlbums/UsersAlbums';
import IconPlus from '../../Components/Icons/IconPlus';
import CreateAlbumBtn from '../../Components/Buttons/CreateAlbumBtn'


interface Album {
  album_name: string,
  album_slug: string,
  id: any
}

function Bookings() {
  const [activeTab, setActiveTab] = useState('inProgress');
  const [avatar, setAvatar] = useState<string | undefined>(undefined);
  const [myBookingRequests, setMyBookingRequests] = useState([]);
  const [flmrBookingReqs, setFlmrBookingRequests] = useState([]);
  const [inProgressBookings, setInProgressBookings] = useState([]);
  const [bookingHistory, setBookingHistory] = useState([]);

  let { userProfile, user } = useUserAuth();
  const storage = getStorage();

  // Fetch data for each category when component mounts or user changes
  useEffect(() => {
    async function fetchBookingRequests() {
      if (user) {
        const requests = await PendingBookingReqs(user);
        setMyBookingRequests(requests);
      }
    }
    fetchBookingRequests();
  }, [user]);

  useEffect(() => {
    async function fetchBookingHistory() {
      if (user) {
        const requests = await BookingHistory(user);
        setBookingHistory(requests);
      }
    }
    fetchBookingHistory();
  }, [user]);

  console.log(bookingHistory);
  
  useEffect(() => {
    async function fetchJobRequests() {
      if (user) {
        const requests = await FlmrBookingReqs(user);
        setFlmrBookingRequests(requests);
      }
    }
    fetchJobRequests();
  }, [user]);

  useEffect(() => {
    async function fetchInProgressBookings() {
      if (user) {
        const requests = await InProgressBookings(user);
        setInProgressBookings(requests);
      }
    }
    fetchInProgressBookings();
  }, [user]);

  // Handle tab selection
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <h1>Albums / Bookings</h1>
      </HeaderWrapper>
      <HRFancy />
      <div className="flex overflow-x-auto overflow-y-hidden border-gray-200 whitespace-nowrap dark:border-gray-700 bg-[#3c444d]">
        <button
          className={`inline-flex items-center px-4 -mb-px text-sm text-center xs:text-base whitespace-nowrap focus:outline-none h-[50px] ${
            activeTab === 'inProgress' ? 'text-[#CBCFD4] border-b-2 border-blue-500 border-[#F27A54]' : 'text-white border-transparent dark:text-white hover:border-gray-400'
          }`}
          onClick={() => handleTabChange('inProgress')}
        >
          In Progress Bookings
        </button>

        <button
          className={`inline-flex items-center px-4 -mb-px text-sm text-center xs:text-base whitespace-nowrap focus:outline-none h-[50px] ${
            activeTab === 'myRequests' ? 'text-[#CBCFD4] border-b-2 border-blue-500 border-[#F27A54]' : 'text-white border-transparent dark:text-white hover:border-gray-400'
          }`}
          onClick={() => handleTabChange('myRequests')}
        >
          My Requests
        </button>

        <button
          className={`inline-flex items-center px-4 -mb-px text-sm text-center xs:text-base whitespace-nowrap focus:outline-none h-[50px] ${
            activeTab === 'pendingRequests' ? 'text-[#CBCFD4] border-b-2 border-blue-500 border-[#F27A54]' : 'text-white border-transparent dark:text-white hover:border-gray-400'
          }`}
          onClick={() => handleTabChange('pendingRequests')}
        >
          Pending Requests
        </button>

        <button
          className={`inline-flex items-center px-4 -mb-px text-sm text-center xs:text-base whitespace-nowrap focus:outline-none h-[50px] ${
            activeTab === 'history' ? 'text-[#CBCFD4] border-b-2 border-blue-500 border-[#F27A54]' : 'text-white border-transparent dark:text-white hover:border-gray-400'
          }`}
          onClick={() => handleTabChange('history')}
        >
          History
        </button>

        <button
          className={`inline-flex items-center px-4 -mb-px text-sm text-center xs:text-base whitespace-nowrap focus:outline-none h-[50px] ${
            activeTab === 'myalbums' ? 'text-[#CBCFD4] border-b-2 border-blue-500 border-[#F27A54]' : 'text-white border-transparent dark:text-white hover:border-gray-400'
          }`}
          onClick={() => handleTabChange('myalbums')}
        >
          My Albums
        </button>
      </div>
      <div className="flex flex-col">

        {/* In Progress Bookings Tab */}
        {activeTab === 'inProgress' && (
          <div className="mt-4">
            {/* <h1 className="mb-4 mt-8">In Progress Bookings</h1> */}
            <div className='flex flex-col'>
              {inProgressBookings.length > 0 ? (
                inProgressBookings.map((req) => (
                  <BookingRequestCard 
                    key={req.id} 
                    document_id={req.document_id} 
                    isFilmer={req.flmr_id === user.uid} 
                    avatar={req.flmr_id === user.uid ? req.booking_user_id : req.flmr_id} 
                    username={req.flmr_username} 
                    media_type={req.booking_media_type} 
                    surf_spot={req.booking_surf_spot} 
                    start_date_time={req.booking_start_date_time} 
                    total_cost={req.booking_cost_total} 
                    booking_cost_net={req.booking_cost_net}
                    session_length={req.booking_length} 
                    booking_user_username={req.booking_user_username} 
                    booking_status={req.booking_status} 
                    inProgress={true} 
                  />
                ))
              ) : (
                <div className="no-request-wrapper"><p>You don't have any currently in progress bookings</p></div>
              )}
            </div>
          </div>
        )}
        
        {/* My Requests Tab */}
        {activeTab === 'myRequests' && (
          <div className="mt-4">
            {/* <h1 className="mb-4 mt-8">My Requested Bookings</h1> */}
            <div className='flex flex-col'>
              {myBookingRequests.length > 0 ? (
                myBookingRequests.map((req) => (
                  <BookingRequestCard 
                    key={req.id} 
                    document_id={req.document_id} 
                    isFilmer={false} 
                    avatar={req.flmr_id} 
                    username={req.flmr_username} 
                    media_type={req.booking_media_type} 
                    surf_spot={req.booking_surf_spot} 
                    start_date_time={req.booking_start_date_time} 
                    total_cost={req.booking_cost_total} 
                    session_length={req.booking_length} 
                    booking_user_username={req.booking_user_username} 
                    booking_status={req.booking_status}/>
                ))
              ) : (
                <div className="no-request-wrapper"><p>You have not booked any surf sessions</p></div>
              )}
            </div>
          </div>
        )}
        
        {/* Pending Requests Tab */}
        {activeTab === 'pendingRequests' && (
          <div className="mb-8">
            {/* <h1 className="mb-4 mt-8">Pending Job Requests</h1> */}
            <div className='flex flex-col pt-4'>
              {flmrBookingReqs.length > 0 ? (
                flmrBookingReqs.map((req) => (
                  <BookingRequestCard 
                    key={req.id} 
                    document_id={req.document_id} 
                    isFilmer={true} 
                    isPendingJob={true}
                    avatar={req.booking_user_id} 
                    username={req.flmr_username} 
                    media_type={req.booking_media_type} 
                    surf_spot={req.booking_surf_spot} 
                    start_date_time={req.booking_start_date_time} 
                    total_cost={req.booking_cost_total} 
                    booking_cost_net={req.booking_cost_net} 
                    session_length={req.booking_length} 
                    booking_user_username={req.booking_user_username} 
                    booking_status={req.booking_status} />
                ))
              ) : (
                <div className="no-request-wrapper"><p>You have no booking requests</p></div>
              )}
            </div>
          </div>
        )}

        {/* History Tab */}
        {activeTab === 'history' && (
          <div className="mb-8">
            {/* <h1 className="mb-4 mt-8">Pending Job Requests</h1> */}
            <div className='flex flex-col pt-4'>
              {bookingHistory.length > 0 ? (
                bookingHistory.map((req) => (
                  <BookingRequestCard 
                    key={req.id}
                    document_id={req.document_id} 
                    isFilmer={true} 
                    canceled={true}
                    isPendingJob={false}
                    avatar={req.booking_user_id} 
                    username={req.flmr_username} 
                    media_type={req.booking_media_type} 
                    surf_spot={req.booking_surf_spot} 
                    start_date_time={req.booking_start_date_time} 
                    total_cost={req.booking_cost_total} 
                    booking_cost_net={req.booking_cost_net} 
                    session_length={req.booking_length} 
                    booking_user_username={req.booking_user_username} 
                    booking_status={req.booking_status} 
                  />
                ))
              ) : (
                <div className="no-request-wrapper"><p>You have no history</p></div>
              )}
            </div>
          </div>
        )}

        {/* My Albums Tab */}
        {activeTab === 'myalbums' && (
          <>
            <div className="mt-4">
              <Link to="/newalbum" className="flex justify-end">
                <div className="w-[155px]">
                  <CreateAlbumBtn>Create Album</CreateAlbumBtn>
                </div>
              </Link>
              <UsersAlbums />
            </div>
          </>
        )}

      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin: 1rem;
  border-radius: 3px;

  .no-request-wrapper {
    border: 1px solid #3c444c;
    padding: 1rem;
  }
  
  p {
    line-height: 20px;
  }

  h1 {
    font-family: 'Montserrat';
    color: #CBCFD4;
    padding: 1rem;
    font-size: 24px;
    font-weight: 900;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3C444C;
  height: 69px;
`

const HRFancy = styled.hr`
  background: linear-gradient(to right, #F27A54, #A154F2);
  height: 5px;
  border-top: none;
`

export default Bookings