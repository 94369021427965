import * as React from 'react';
import styled from 'styled-components'
import IconUsers from '../../Components/Icons/IconUsers'

interface AlbumCardPhotographerProps {
  photographer: string
}

function AlbumCardPhotographer(props: AlbumCardPhotographerProps) {
  return (
    <Wrapper>
      <IconUsers />
      <p>{props.photographer}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 7%;
  width: auto;
  height: 35px;
  background: #30363d;
  border-radius: 0px 20px 20px 0px;

  p {
    padding-left: 15px;
    font-size: 10px;
    font-weight: 300;
    color: white;
  }
  
`

export default AlbumCardPhotographer