import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { listAll, getDownloadURL } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import { storage } from '../../Services/firebase/FirebaseConfig';
import { Link, useParams } from 'react-router-dom';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import SwiperThumbnail from '../../Components/SwiperThumbnails/SwiperThumbnail';

interface AlbumProps {
  user: any;
  owner: string;
  album_spot: string;
  album_name: string;
  downloadUrl: any;
  imageName: any;
}

function Album(props: AlbumProps) {
  const db = getFirestore();

  const [imageList, setImageList] = useState([]);
  const [imageListThumbnails, setImageListThumbnails] = useState([]);
  const [currentAlbum, setCurrentAlbum] = useState<any>(null);
  const [userAvatar, setUserAvatar] = useState('');
  const [itemsToShow, setItemsToShow] = useState(16);
  const { id, albumspot, albumname } = useParams<{ id: string; albumspot: string; albumname: string }>();
  const [renderedImages, setRenderedImages] = useState([]);
  const [clickedImageUrl, setClickedImageUrl] = useState<string | null>(null); // Track clicked image

  const albumRefThumbnails = '/albums/' + id + '/' + albumspot + '/' + albumname + '/thumbnails';
  const albumRefFullSize = '/albums/' + id + '/' + albumspot + '/' + albumname;

  // Fetch album data
  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, 'albums'), where('album_name', '==', albumname));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setCurrentAlbum(doc.data());
      });
    };
    fetchData();
  }, []);

  // Get list of thumbnails
  useEffect(() => {
    const fileUploadRef = sRef(storage, albumRefThumbnails);
    listAll(fileUploadRef)
      .then((res) => {
        const downloadPromises = res.items.map((itemRef) => {
          return getDownloadURL(sRef(storage, itemRef.fullPath));
        });
        Promise.all(downloadPromises)
          .then((urls) => {
            if (currentAlbum?.album_media_price > 0) {
              const watermarkedUrls = urls.filter((url) => url.includes('_watermarked'));
              setImageListThumbnails(watermarkedUrls);
            } else if (currentAlbum?.album_media_price === 0) {
              const originalUrls = urls.filter((url) => !url.includes('_watermarked'));
              setImageListThumbnails(originalUrls);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error, 'storage listing error');
      });
  }, [currentAlbum]);

  // Get full-size images
  useEffect(() => {
    const fileUploadRef = sRef(storage, albumRefFullSize);
    listAll(fileUploadRef)
      .then((res) => {
        const downloadPromises = res.items.map((itemRef) => {
          return getDownloadURL(sRef(storage, itemRef.fullPath));
        });
        Promise.all(downloadPromises)
          .then((urls) => {
            if (currentAlbum?.album_media_price > 0) {
              const watermarkedUrls = urls.filter((url) => url.includes('_watermarked'));
              setImageList(watermarkedUrls);
            } else if (currentAlbum?.album_media_price === 0) {
              const originalUrls = urls.filter((url) => !url.includes('_watermarked'));
              setImageList(originalUrls);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error, 'storage listing error');
      });
  }, [currentAlbum]);

  // Get users profile image
    useEffect(() => {
      const storageRef = storage; 
      getDownloadURL(sRef(storageRef, `user_images/${id}/avatar/user_avatar.png`))
          .then((url) => {
            setUserAvatar(url);
          })
          .catch((error) => {
            console.log("Album Card Avatar Error: ", error);
          });
    }, [props.album_name]);

  return (
    <AlbumWrapper>
      <AlbumInfoWrapper>
        <div className="flex items-center">
          {/* <Link to={`/profile/${props.album_owner_username}/${props.album_owner_id}`}> */}
          <Link to={`/profile/${currentAlbum?.album_owner_username}/${id}`}>
            <UserImg className="w-12 h-12 rounded-full shadow-lg" src={userAvatar} alt=""/>
          </Link>
          <div className="pl-2">
            <FancyText>{albumname}</FancyText>
            <Link to={`/profile/${currentAlbum?.album_owner_username}/${id}`}>
              <p className="">{currentAlbum?.album_owner_username}</p>
            </Link>
          </div>
        </div>
        <div className="text-right items-center flex-col">
          <p className="font-bold">{currentAlbum?.session_date}</p>
          <p>{albumspot}</p>
          {/* <button className="border py-1 px-2 rounded-sm text-[#CBCFD4]">Add to Cart</button>
          <button className="border py-1 px-2 rounded-sm text-[#CBCFD4]">Favorite</button> */}
        </div>
      </AlbumInfoWrapper>
      <PhotoSectionWrapper>
        {currentAlbum && 
           <SwiperThumbnail 
              images={imageList}
              thumbnails={imageListThumbnails}
              albumInfo={currentAlbum}
            />
        }
      </PhotoSectionWrapper>
    </AlbumWrapper>
  );
}

const AlbumWrapper = styled.div`
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-radius: 3px;

  h1 {
    font-family: 'Montserrat';
    color: #cbcfd4;
    font-size: 24px;
    font-weight: 900;
  }
`;

const AlbumInfoWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #3d444c; */
  margin-bottom: 1rem;
  border-radius: 4px;

  p {
    line-height: 20px;
  }
`;

const PhotoSectionWrapper = styled.div`
  /* margin-top: 1rem; */
`;

const FancyText = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  background: -webkit-linear-gradient(60deg, #F27A54, #A154F2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (max-width: 525px) {
    font-size: 18px;
  }
`

const UserImg = styled.img`
  object-fit: cover;
  background-color: #3c444c;
  padding: 2px;
`

export default Album;
