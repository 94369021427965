import React, {useState, useEffect} from 'react';
import { useUserAuth } from '../../Context/UserAuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { getStorage, getDownloadURL } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import styled from 'styled-components';

function UserProfileMenu() {

  const { user, logOut, userProfile } = useUserAuth();
  const [isActive, setActive] = useState(false);
  const [avatar, setAvatar] = useState();
  const navigate = useNavigate();
  const storage = getStorage();
  let profileImgUrl;
  
  // Get users profile image from storage
  useEffect(() => {
    if (userProfile) {
      const profileImgRef = sRef(storage, `user_images/${user.uid}/avatar/user_avatar.png`);
      getDownloadURL(profileImgRef)
        .then((url) => {
          console.log(url); // Optional: Log the URL to verify
          setAvatar(url);
        })
        .catch((error) => {
          console.error("Error fetching avatar URL:", error);
        });
    }
  }, [userProfile]);

  const handleMenu = () => {
    setActive(!isActive);
  };
  
  const handleLogOut = async () => {
    try {
      await logOut();
      navigate('/');
    } catch (err) {
      console.log(err.message);
    }
  }

  return (
  <div id="profileButton" onClick={handleMenu} className="relative inline-block text-left">
    <div className="flex">
      <ProfileIcon className="inline-flex justify-center w-full text-sm font-medium text-gray-700 focus:outline-none ml-6" id="menu-button" aria-expanded="true" aria-haspopup="true">
        <img src={avatar} onClick={handleMenu} className={isActive ? 'active': null} style={{objectFit: 'cover'}}/>
      </ProfileIcon>
    </div>
    { isActive ? <UserMenuDropdown id="profile-menu" className="origin-top-right absolute right-0 w-56 shadow-lg ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none mt-4" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1" >
      <Userbar className="userbar" role="none">
        {/* <p className="text-white block py-2 pl-4 text-sm gray-50">{user.email}</p> */}
        <p className="text-white block px-4 py-2"><span className="font-semibold">Username: </span>{ userProfile?.username }</p>
      </Userbar>
      {/* <div className="bg-white wallet flex justify-between px-4 cursor-pointer hover:bg-gray-50 items-center" role="none">
        <Link to="/profile" className="block  py-2 text-sm ">Available Balance</Link>
        <p style={{color: '#30363d'}}>$0.00</p>
      </div> */}
      <div className="bg-white wallet" role="none">
        <Link to="/profile" className="block px-4 py-2 text-sm hover:bg-gray-50">Profile</Link>
      </div>
      <div className="bg-white wallet" role="none">
        <Link to="/newalbum" className="block px-4 py-2 text-sm hover:bg-gray-50">New Album</Link>
      </div>
      <div onClick={handleLogOut} className="bg-white btn-logout rounded-b-sm hover:bg-gray-50" role="none">
        <a href="#" className="block px-4 py-2 text-sm" role="menuitem" tabIndex="-1">Sign Out</a>
      </div>
    </UserMenuDropdown> : null
    }
  </div>
  )
}

const UserMenuDropdown = styled.div`
  .active {
    display: block;
  }
`

const Userbar = styled.div`
  background: linear-gradient(90deg,#F27A54 0%,#A154F2 100%);
  border-radius: 4px 4px 0px 0px;
`

const ProfileIcon = styled.div`
  cursor: pointer; 

  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  .active {
    border: 1px solid #CBCFD4;
    border-radius: 50%;
    padding: 2px;
    transform: scale(1.2);
  }
`

export default UserProfileMenu
